@charset "UTF-8";
@font-face {
  font-family: "ryumin-l";
  src: url("../asset/font/ryumin-l.woff2") format("woff2"), url("../asset/font/ryumin-l.woff") format("woff"), url("../asset/font/ryumin-l.otf") format("opentype");
}

@font-face {
  font-family: "ryumin-m";
  src: url("../asset/font/ryumin-m.woff2") format("woff2"), url("../asset/font/ryumin-m.woff") format("woff"), url("../asset/font/ryumin-m.otf") format("opentype");
}

body, .sub-ttl span {
  font-size: 1.6rem;
  font-family: "ryumin-l", serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.53125;
}

.sub-ttl, .tel-number, .copy-txt, .header-tel-ttl {
  font-family: "Crimson Text", "Noto Serif JP", serif;
  font-style: normal;
}

[data-target] {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: #282828;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: normal;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  font-feature-settings: "palt";
  min-width: 1366px;
}

body[data-loading="true"] {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    min-width: unset;
  }
}

ul,
li {
  list-style: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

video {
  max-width: 100%;
  height: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, sans-serif;
  font-size: inherit;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

@media (max-width: 767px) {
  main {
    margin-top: 60px;
  }
}

form {
  margin: 0;
  padding: 0;
}

form button {
  width: auto;
  display: inline-block;
  padding: 0.6em 2em;
  margin: 0;
  background-image: none;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 0;
  font-size: 1.6rem;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.3s ease 0s;
}

form button:hover, form button:focus {
  outline: none;
}

form button:hover {
  opacity: 0.7;
}

form button ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 0;
  background-image: none;
  font-family: inherit;
  font-size: 1.6rem;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f4f4f4;
}

textarea:hover, textarea:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}

textarea ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
}

input[type="submit"], input[type="button"] {
  width: auto;
  display: block;
  padding: 0;
  margin: 0;
  background-image: none;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: 1px solid #282828;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.2s ease 0s;
  background: #fff;
}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="button"]:hover, input[type="button"]:focus {
  outline: none;
  background: #282828;
  color: #fff;
}

input[type="submit"]:hover, input[type="button"]:hover {
  opacity: 0.7;
}

input[type="submit"] ::-moz-focus-inner, input[type="button"] ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + span {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.2em 0;
  padding: 0 0 0 2em;
  position: relative;
}

input[type="radio"] + span::before {
  -webkit-transform: translateY(-50%);
  background: #fff;
  border: 1px solid #282828;
  border-radius: 0px;
  content: "";
  display: block;
  height: 1em;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}

input[type="radio"] + span::after {
  -webkit-transform: translateY(-50%) rotate(-45deg);
  border-bottom: 3px solid #282828;
  border-left: 3px solid #282828;
  content: "";
  display: block;
  height: 0.6em;
  left: 0;
  margin-top: -0.2em;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all 0.3s ease 0s;
  width: 1em;
}

input[type="radio"]:checked + span::after {
  opacity: 1;
}

/* **************************************


     common


***************************************/
.inner {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 1160px;
  height: inherit;
  position: relative;
  min-width: 1160px;
}

@media (max-width: 1159px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
    min-width: 1160px;
  }
}

@media (max-width: 767px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
    min-width: auto;
  }
}

.map {
  position: relative;
  width: 100%;
}

.map:before {
  content: "";
  display: block;
  padding-top: 35%;
  /* 高さを幅の75%に固定 */
}

@media (max-width: 767px) {
  .map:before {
    padding-top: 75%;
  }
}

.map iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.sub-footer {
  background: rgba(13, 183, 214, 0.3);
  position: relative;
}

.sub-footer-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 88px;
}

@media (max-width: 767px) {
  .sub-footer-flex {
    padding-left: 0;
    flex-direction: column-reverse;
  }
}

.sub-footer-img {
  position: relative;
  width: 685px;
}

.sub-footer-img img {
  position: absolute;
  top: -100px;
  left: calc(50% + 50px);
  transform: translate(-50%, -50%);
  width: 685px;
  max-width: unset;
}

@media (max-width: 767px) {
  .sub-footer-img {
    width: 100%;
    top: -50px;
  }
  .sub-footer-img img {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
  }
}

.sub-footer-box {
  padding: 100px 0;
  max-width: 500px;
  width: 100%;
}

@media (max-width: 767px) {
  .sub-footer-box {
    padding: 40px 0 60px;
  }
}

.sub-footer-ttl {
  font-size: 3.2rem;
}

@media (max-width: 767px) {
  .sub-footer-ttl {
    font-size: 2.2rem;
  }
}

.tel-ttl {
  font-size: 2.4rem;
}

.tel-ttl span {
  font-size: 60%;
}

@media (max-width: 767px) {
  .tel-ttl {
    font-size: 1.8rem;
  }
}

.sub-footer-tel {
  font-size: 4.8rem;
  transition: 0.2s;
  position: relative;
}

.sub-footer-tel:hover {
  opacity: 0.7;
}

.sub-footer-tel img {
  vertical-align: baseline;
  margin-right: 4px;
}

@media (max-width: 767px) {
  .sub-footer-tel {
    font-size: 4rem;
  }
}

.sub-footer-email {
  display: inline-block;
  width: 330px;
  height: 75px;
  border: 1px solid #282828;
  margin-top: 48px;
  text-align: center;
  line-height: 75px;
  transition: 0.2s;
}

.sub-footer-email i {
  margin-right: 8px;
  font-size: 2rem;
}

.sub-footer-email span {
  display: inline-block;
}

.sub-footer-email:hover {
  color: #fff;
  background: #282828;
}

@media (max-width: 767px) {
  .sub-footer-email {
    max-width: 330px;
    width: 100%;
  }
}

.sub-mv {
  height: 360px;
  position: relative;
}

.sub-mv::before {
  position: absolute;
  top: 50%;
  left: calc(50% + 50px);
  transform: translate(-50%, -50%);
  content: "";
  width: 1266px;
  height: 100%;
  background: url(../images/common/sub-mv.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .sub-mv {
    height: 300px;
  }
  .sub-mv:before {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.sub-ttl {
  padding: 32px 0 0 48px;
  line-height: 1;
  color: #fff;
  font-size: 6.4rem;
}

.sub-ttl span {
  display: block;
}

@media (max-width: 767px) {
  .sub-ttl {
    font-size: 4rem;
    padding: 16px 0 0 0px;
  }
}

.mv-img {
  position: relative;
  width: 100%;
}

.mv-img img {
  position: absolute;
  left: 100px;
  max-width: unset;
  width: 1266px;
}

.tel-number {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 7.2rem;
}

.tel-number img {
  margin-right: 8px;
  vertical-align: baseline;
}

@media (max-width: 767px) {
  .tel-number img {
    width: 25px;
  }
}

@media (max-width: 767px) {
  .tel-number {
    font-size: 3rem;
  }
}

.main {
  overflow-x: hidden;
}

.drawer {
  z-index: 40;
  width: 100%;
}

@media (max-width: 767px) {
  .drawer {
    width: 40px;
    position: fixed;
    top: 20px;
    right: 10px;
    height: auto;
    border: none;
  }
}

.drawer-close {
  display: none;
  position: fixed;
  z-index: 39;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.drawer-close.is-checked {
  display: block;
  opacity: 0.4;
}

.drawer-icon {
  display: none;
  position: relative;
  z-index: 41;
  text-align: center;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

@media (max-width: 767px) {
  .drawer-icon {
    display: inline-block;
  }
}

.drawer-icon:focus {
  outline: none;
}

.drawer-icon::after {
  content: "MENU";
  display: none;
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
  line-height: 1;
  margin-top: 4px;
  font-weight: 700;
}

.drawer-icon.is-checked::after {
  content: "CLOSE";
}

.drawer-icon.is-checked + body {
  overflow: hidden;
}

.drawer-bars {
  display: inline-block;
  width: 30px;
  height: 16px;
  position: relative;
  vertical-align: bottom;
}

.drawer-bars:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  content: "";
  z-index: -1;
  border-radius: 50%;
}

.drawer-bar {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  background: #595757;
  width: 100%;
  height: 2px;
  border-radius: 3px;
  transition: all 0.3s linear 0s;
}

.drawer-bar:nth-of-type(1) {
  top: 0;
}

.drawer-bar:nth-of-type(2) {
  top: 7px;
}

.drawer-bar:nth-of-type(3) {
  top: 14px;
}

.is-checked .drawer-bar:nth-of-type(1) {
  top: 10px;
  transform: rotate(-45deg);
}

.is-checked .drawer-bar:nth-of-type(2) {
  background: transparent;
}

.is-checked .drawer-bar:nth-of-type(3) {
  top: 10px;
  transform: rotate(45deg);
}

@media (max-width: 767px) {
  .drawer-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateX(105%);
    z-index: 40;
    width: 200px;
    max-width: 90%;
    height: 100%;
    background: #fff;
    transition: all 0.5s ease 0s;
    padding: 50px 16px 0;
    text-align: left;
    color: translate;
  }
  .drawer-content.is-checked {
    transform: translateX(0);
    box-shadow: 6px 0 25px rgba(0, 0, 0, 0.16);
  }
}

@media (max-width: 767px) {
  .drawer-content--left {
    transform: translateX(-105%);
    left: 0;
    right: auto;
  }
  .drawer-content--top {
    transform: translateY(-105%);
    top: 0;
    bottom: auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .drawer-bg.is-checked {
    width: 100vw;
    height: 100vh;
    /*     background-color: #000; */
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.7;
    transition: 0.4s;
  }
}

.g-nav {
  margin: 24px auto;
  text-align: justify;
}

.g-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .g-list {
    flex-direction: column;
  }
}

.g-item {
  position: relative;
}

.g-link {
  margin: 0 1em;
  padding: 0 1em;
  position: relative;
}

.g-link:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: #282828;
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform 0.3s;
}

.g-link:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

@media (max-width: 767px) {
  .g-link {
    height: auto;
    padding: 10px 0;
    display: block;
    font-size: 1.6rem;
    text-align: center;
  }
}

body.is-checked {
  overflow: hidden;
}

/* =============================================================================


     footer


============================================================================= */
footer {
  position: relative;
  min-width: 1366px;
}

@media (max-width: 767px) {
  footer {
    min-width: unset;
  }
}

.footer-contents {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0;
}

@media screen and (max-width: 768px) {
  .footer-contents {
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
  }
}

.footer-img {
  position: relative;
  width: 800px;
}

.footer-img img {
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .footer-img {
    margin: 0px calc(50% - 50vw) 24px;
    width: 100vw;
  }
  .footer-img img {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}

.footer-company {
  max-width: 400px;
  width: 400px;
}

@media screen and (max-width: 767px) {
  .footer-company {
    width: 100%;
    margin: auto;
  }
}

.footer-logo {
  width: 100%;
}

.footer-link {
  display: block;
  border: 1px solid #282828;
  height: 50px;
  line-height: 50px;
  text-align: center;
  transition: 0.2s;
  margin-top: 16px;
}

.footer-link:hover {
  opacity: 0.7;
}

.footer-info {
  font-size: 1.4rem;
  margin-top: 48px;
}

@media (max-width: 767px) {
  .footer-info {
    font-size: 1.2rem;
  }
}

.copy-txt {
  font-size: 1.2rem;
  text-align: center;
  background: #282828;
  color: #fff;
  line-height: 50px;
}

@media screen and (max-width: 767px) {
  .copy-txt {
    font-size: 1rem;
  }
}

/* =============================================================================


     header


============================================================================= */
header {
  min-width: 1366px;
}

@media (max-width: 767px) {
  header {
    min-width: unset;
    position: fixed;
    z-index: 20;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    height: 60px;
    top: 0;
    left: 0;
  }
}

.header-contents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .header-contents {
    height: 60px;
  }
}

.header-logo {
  font-size: 3rem;
}

.header-link {
  display: flex;
  transition: 0.2s;
  align-content: center;
}

.header-link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .header-link {
    width: 250px;
  }
}

.header-btn-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.header-btn {
  width: 250px;
  color: #fff;
}

.header-btn a {
  transition: 0.2s;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #282828;
}

.header-btn a:hover {
  background: #0db7d6;
}

.header-btn + figure {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .header-btn {
    width: 250px;
    margin-top: 30px;
  }
}

.header-contact {
  font-size: 2.4rem;
  text-align: center;
}

.header-contact i {
  margin-right: 4px;
}

.header-contact span {
  display: block;
  font-size: 1.2rem;
}

.header-tel {
  text-align: center;
}

.header-tel img {
  vertical-align: baseline;
  margin-right: 4px;
}

.header-tel-ttl {
  font-size: 3rem;
  line-height: 1;
}

.header-tel-ttl span {
  display: block;
  font-size: 1.2rem;
  margin-top: 8px;
}

/* #header.transform .header-logo {
  padding: 25px 25px 25px 0;

  &:before {
    left: -25px;
    width: 25px;
  }

  img {
    width: 215px;
  }
} */
.tab-none {
  display: block;
}

@media (max-width: 1159px) {
  .tab-none {
    display: none;
  }
}

.tab-dis {
  display: none;
}

@media (max-width: 1159px) {
  .tab-dis {
    display: block;
  }
}

.sp-none {
  display: block;
}

@media (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

.sp-dis {
  display: none;
}

@media (max-width: 767px) {
  .sp-dis {
    display: block;
  }
}
